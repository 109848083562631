<template>
    <div class="w3-theme-l1 w3-container  w3-responsive" style="padding-top: 16px;padding-bottom:16px" v-bind:style="'height:'+  (screenHeight-85) +'px;'">
        Dashboard
    </div>
</template>
<script>

export default {
    head: {
        title: {
            inner: "Main"
        }
    },
    name : "Main",
    components: {
      
    },
    data() {
        return {
            screenHeight: 0,
            screenWidth: 0,
            admin: localStorage.dcode
        };
    },
    methods : {
        
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;
        // eslint-disable-next-line no-console
        console.log(this.getObjFromStoreage("tahunajaran"));
        // eslint-disable-next-line no-console
        console.log(this.getObjFromStoreage("user"));
    }
};
</script>
